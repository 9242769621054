<template>
  <TopBar />
  <Navbar />
  <div class="container">
    <router-view />
  </div>
  <footer>
    <hr />
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/Pasture">Pasture Measurement</router-link> |
      <router-link to="/Mapping">Farm Mapping</router-link> |
      <router-link to="/Temperature">Temperature Monitoring</router-link> |
      <router-link to="/ContactUs">Contact Us</router-link>
    </div>
  </footer>
</template>

<script>
import TopBar from "./components/TopBar.vue";
import Navbar from "./components/Navbar.vue";
import PictLink from "./components/PictLink.vue";
import Hero from "./components/Hero.vue";
import { onBeforeMount } from "@vue/runtime-core";

export default {
  name: "App",
  components: {
    TopBar,
    Navbar,
    PictLink,
    Hero,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media only screen and (min-width: 1080px) {
  .container {
    width: 1080px;
    margin: 0 auto;
  }

  section {
    padding: 4em 4em;
  }
}

@media only screen and (min-width: 1100px) {
  .container {
    width: 1100px;
    margin: 0 auto;
  }
}
</style>
