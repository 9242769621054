<template>
  <info-page 
        message = "Need your pastures measured? Or have some questions? Get in touch, we will be happy to help."
  >
    <div class="mapping-info">
      <h1>Pasture Measurements</h1>
      <div>
        <div class="mapping-top">
          <img src="../photos/donald-pasture.jpg" alt="Donald" />
          GrassCo specialises in measuring the dry matter per hectare. With our
        custom built pasture sledge incorporating a GPS track, we offer
        accurate, consistent and reliable information to track and forecast
        pasture growth, making those Farm Management jobs a breeze. We service
        the whole of <b>Southland</b> and <b>South to West Otago</b>,
        <b>Te Anau</b>, <b>Tuatapere</b>, <b>The Caitlin’s</b>,
        <b>Balclutha</b>, <b>Clydevale</b>, <b>Tapanui</b> and everywhere in
        between.
        </div>
      </div>
      <div class="mapping-centre">
        <b>Here at GrassCo we are all about dry matter. Our GPS tracking pasture
          meter is accurate and consistent and allows us to process and produce
          useful data in a shorter spaceee of time.</b>
      </div>
      <div class="mapping-bottom">
        <div class="flex-item">
          <img src="../photos/cover-per-padock.jpg" alt="map1" />          
        </div>
        <div class="flex-item">
          <img src="../photos/dairy-season.jpg" alt="map2" />
        </div>
        <div class="flex-item">
          <img src="../photos/dry-matter-data-monitoring-v2.jpg" alt="map3" />
        </div>
        <div class="flex-item">
          <img src="../photos/dry-matter-data-monitoring.jpg" alt="map3" />
        </div>
        <div class="flex-item">
          <img src="../photos/dry-matter-data-v3.jpg" alt="map3" />
        </div>
        <div class="flex-item">
          <img src="../photos/rosebank.jpg" alt="map3" />
        </div>
      </div>
    </div>
  </info-page>
</template>

<script>
import InfoPage from "../components/InfoPage.vue";

export default {
  name: "Mapping",
  components: {
    InfoPage,
  },
};
</script>

<style lang="scss">
.mapping-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 2em;
    color: green;
    text-align: left;
  }


  .mapping-top {
    font-size: 1.1em;
    text-align: justify;
    img {
      float: left;
      width: 200px;
      padding-right: 1.5em;
      padding-bottom: 1.5em;
      margin: 0;
      vertical-align: text-top;
    }
  }
  .mapping-centre {
    font-size: 1.1em;
    text-align: justify;
  }

  .mapping-bottom {
    margin-top: 20px;
    background: darkgreen;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: row wrap;
  }

  .flex-item {
    padding: 10px;
    width: 33.3%;
    order: 1;
  }
   .flex-item img {
     max-width: 100%;
     object-fit: contain;
   }

  li {
    font-weight: bold;
  }

  .mapping-intro {
    font-weight: bold;
  }

  @media only screen and (min-width: 1080px) {

  }

}
</style>
