<template>
  <div class="navbar">
    <div class="container">
      <router-link to="/" @click="closeNav">
        <img src="../images/logo.jpg" alt="Grassco" class="logo"
      /></router-link>
      <img
        id="mobile-cta"
        class="mobile-menu"
        src="../images/menu.svg"
        alt="Open Navigation"
        @click="btnOpenMenu"
      />
      <nav>
        <img
          id="mobile-exit"
          class="mobile-menu-exit"
          src="../images/menu.svg"
          alt="Exit Navigation"
          @click="btnCloseMenu"
        />
        <ul class="primary-nav">
          <li class="current"><router-link to="/" @click="closeNav">Home</router-link></li>
          <li><router-link to="/about" @click="closeNav">About</router-link></li>
          <li><router-link to="/pasture" @click="closeNav">Pasture Measurement</router-link></li>
          <li><router-link to="/mapping" @click="closeNav">Farm Mapping</router-link></li>
          <li>
            <router-link to="/temperature" @click="closeNav">Temperature Monitoring</router-link>
          </li>
          <li><router-link to="/contactus" @click="closeNav">Contact Us</router-link></li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
//import '../public/main.scss'

function btnOpenMenu() {
  const nav = document.querySelector("nav");
  nav.classList.add("menu-btn");
}

function btnCloseMenu() {
  const mobileBtn = document.getElementById("mobile-cta");
  const nav = document.querySelector("nav");
  const mobileBtnExit = document.getElementById("mobile-exit");
  nav.classList.remove("menu-btn");
}

function closeNav() {
  const mobileBtn = document.getElementById("mobile-cta");
  const nav = document.querySelector("nav");
  const mobileBtnExit = document.getElementById("mobile-exit");
  nav.classList.remove("menu-btn");
}
</script>

<style scoped lang="scss">
img.logo {
  width: 200px;
  height: auto;
}
.navbar {
  background: white;
  padding: 1em;

  nav {
    display: none;
  }

  .container {
    display: flex;
    place-content: space-between;
  }

  .mobile-menu {
    cursor: pointer;
  }
}

nav.menu-btn {
  display: block;
}

nav {
  position: fixed;
  z-index: 999;
  width: 66%;
  right: 0;
  // top: 0;
  background: #fafafa; //var(--gray-shade);
  height: auto; //100vh; //vh = height of device
  padding: 1em;

  ul.primary-nav {
    margin-top: 5em;
  }

  li {
    a {
      /*color: var(--primary-color);*/
      color: green;
      text-decoration: none;
      display: block;
      padding: 0.5em;
      font-size: 1.3em;
      text-align: right;

      &:hover {
        font-weight: bold;
        color: red;
      }
    }
  }
}

.mobile-menu-exit {
  float: right;
  margin: 0.5em;
  //background: #fafafa;
  cursor: pointer;
}

a {
  color: #444444;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu,
  .mobile-menu-exit {
    display: none;
  }

  .navbar .container {
    display: grid;
    grid-template-columns: 220px auto;
    //justify-content: unset;
  }

  .navbar nav {
    display: flex;
    justify-content: space-between;
    background: none;
    position: unset;
    height: auto;
    width: 100%;
    padding: 0;
    padding-right: 0.5em;

    ul {
      display: flex;
    }

    a {
      color: black;
      font-size: 1.2em;
      text-align: center;
      padding: 0.1em 1em;
      /*width: 100px;*/
      height: 50px;
      /*display: block;*/
      /*background: #fafafa;*/
      margin: 0 1px 0 1px;
    }

    ul.primary-nav {
      margin: 0;
    }

    li.current a {
      font-weight: bold;
    }
  }
}
</style>
