<template>
  <section class="hero-section">
    <div>
      <Slider />
      <ul>
        <li>
          <PictLink
            title="Temperature"
            desc="We can accurately measure the grass cover and grass growth in your paddock."
            :src="require('../images/temperature.jpg')"
             url="Temperature"

          />
        </li>
        <li>
          <PictLink
            title="Farm Mapping"
            desc="Using detailed GPS tracking we can generate accurate information to help you manage your pasture."
            :src="require('../images/map.jpg')"
             url="Mapping"
          />
        </li>
        <li>
          <PictLink
            title="GrassCo Cover"
            desc="Know your Farm. Determine the factors effecting grass growth on your paddock."
            :src="require('../images/grass.jpg')"
             url="Pasture"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import PictLink from "../components/PictLink.vue";
import Slider from "../components/Slider.vue";

export default {
  name: "Hero",
  components: {
    PictLink,
    Slider,
  },
};
</script>

<style scoped lang="scss">
.hero-section {
  text-align: center;
  background: white;

  .hero-img {
    display: none;
  }

  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

}
  @media only screen and (min-width: 1080px) {
    .hero-section {
      .hero-img {
        display: block;
        position: relative;
        //top: 5 em;
        //right: 0;
        width: 100%;
        //z-index: 0;
        //bottom: 10em;
      }
    }
    .hero-section ul {
      width: 100%;
      display: flex;
      //z-index: 10;
      margin-top: -30px;
      padding: 0;
      //justify-content: space-evenly;
      li {
        margin: 0 1em;
        top: -8em;
        z-index: 999;
      }
    }
  }
</style>
