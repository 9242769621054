

<template>
    <div class="top-line">
        <img src="../images/ph-icon.png" alt="Phone:">
        (03) 206 4668
    </div>
</template>

<script setup>
import { defineProps, reactive } from 'vue'

defineProps({
  msg: String
})

const state = reactive({ count: 0 })
</script>

<style scoped lang="scss">
.top-line {
    background-color: white;
    color: black;
    text-align: right;
    padding-top: .5em;
    padding-right: .5em;
    margin: 0;
}
</style>
