<template>
  <div class="component">
    <div class="slider">
      <!--button @click="prev">&lt;</button-->
      <!--button @click="next">&gt;</button-->
      <img :src="image" />
    </div>
  </div>
</template>

<script>
const images = [
  require("../photos/quad-bike-1-slider.jpg"),
  require("../photos/quad-bikes-together-1-slider.jpg"),
  require("../photos/quad-bike-with-sled--slider.jpg"),
  require("../photos/quad-bike-with-sled--slider-2.jpg"),
  require("../photos/slider.jpg"),
  require("../photos/trucks-1-slider.jpg"),
];
export default {
  name: "Slider",
  data() {
    return {
      index: 0,
      image: images[0],
    };
  },
  methods: {
    next() {
      this.index = (this.index + 1) % images.length;
      this.image = images[this.index];
    },
    prev() {
      if (this.index == 0) this.index = images.length - 1;
      else this.index--;
      this.image = images[this.index];
    },
    autoChangeSlide() {
      setInterval(() => {
        this.next();
      }, 3000);
    },
  },
  beforeMount() {
    this.autoChangeSlide();
  },
};
</script>

<style scoped lang="scss">

.slider {
  img {
    max-width:100%;
  }
}
</style>
