import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Pasture from '../views/Pasture.vue'
import Mapping from '../views/Mapping.vue'
import Temperature from '../views/Temperature.vue'
import ContactUs from '../views/ContactUs.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Pasture',
    name: 'Pasture',
    component: Pasture
  },
  {
    path: '/Mapping',
    name: 'Mapping',
    component: Mapping
  },
  
  {
    path: '/Temperature',
    name: 'Temperature',
    component: Temperature
  },
  
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
