<template>
  <section class="assurance-section">
    <div>
      <h2>GrassCo can measure pasture mass and growth of your pasture.</h2>
      <div class="assurance">
        <img src="../images/graph.png" alt="Graph" />
        <span class="assurance-intro"
          >GrassCo is a Southland owned and operated business that is here to
          help you with all your pasture management needs.</span
        >
        We specialise in measuring dry matter and Farm Mapping, to ensure you
        are making the most of your land. We pride ourselves on providing
        detailed information regarding pasture covers and growths, so you can
        make an informed decision regarding how you manage your farm. We use
        several different methods to gather the information you need, which
        includes the use of a custom built pasture sledge that follows a
        predetermined GPS track, unique to GrassCo. So contact us today to find
        out how we can help you.
      </div>
    </div>
    <div class="quote">
      <div class="container">
        <blockquote class="quote">
          At GrassCo, we are all about the available dry matter per hectare. Let
          us get the info for you, so you can get on with the real work.
        </blockquote>

        <img src="../photos/quote-picture.jpg" alt="Quote" />
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style scoped lang="scss">
.assurance-section {
  text-align: center;
  background: white;
  min-height: 300px;
  color: var(--primary-color);
  padding: 1em;

  h2 {
    font-size: 1.8em;
    text-align: left;
  }

  .assurance {
    font-size: 1.1em;
    text-align: justify;
    img {
      float: left;
      width: 200px;
      padding-right: 1.5em;
      padding-bottom: 1.5em;
      margin: 0;
      vertical-align: text-top;
    }
  }

  .assurance-left {
    flex: 200px;
  }

  .assurance-intro {
    font-weight: bold;
  }
  .assurance-right {
    flex: auto;
  }

  .container {
    display: none;
    flex-direction: row;
    background-color: #fafafa;
    justify-content: space-between;
    align-items: center;
  }

  .image > img {
    display: block;
    max-width: 100%;
    max-height: 300px;
  }

  blockquote {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1080px) {
  .assurance-section {
    .container {
      display: flex;
    }
  }
}
</style>
