<template>
  <info-page 
    message="Need a detailed map of your land? Get in touch, we will be happy to help."
  >
    <div class="mapping-info">
      <h1>Farm Mapping</h1>
      <div>
        <div class="mapping-top">
          <img src="../photos/donald-mapping.jpg" alt="Graph" />
          <span class="mapping-intro">
            Knowing the boundaries and natural landscape of your farm is
            beneficial information to the overall planning and development of
            your land.
          </span>
          With our Farm Mapping service, GrassCo can provide you with a detailed
          map in colour of your land which in turn can become one of the most
          useful resource tools available to you. Using GPS information, our
          Farm mapping service offers valuable data that will help you make the
          most of your property and its potential.
        </div>
      </div>
      <div class="mapping-centre">
        This information can be used in a number of different ways from
        identifying the boundaries and naturally occurring features to providing
        essential information to help you plan, maintain and develop your land.
        <ul>
          <li>
            Accurate hectares of farm map areas to work in conjunction with
            GrassCo data
          </li>
          <li>Help with application of fertilizer</li>
          <li>Help with effluent spread</li>
          <li>Used for proof of placement of effluent spread</li>
          <li>Day to day operations</li>
        </ul>
      </div>
      <div class="mapping-bottom">
        <div class="flex-item">
          <img src="../photos/map-1.jpg" alt="map1" />          
        </div>
        <div class="flex-item">
          <img src="../photos/map-2.jpg" alt="map2" />
        </div>
        <div class="flex-item">
          <img src="../photos/map-3.jpg" alt="map3" />
        </div>
      </div>
    </div>
  </info-page>
</template>

<script>
import InfoPage from "../components/InfoPage.vue";

export default {
  name: "Mapping",
  components: {
    InfoPage,
  },
};
</script>

<style lang="scss">
.mapping-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 2em;
    color: green;
    text-align: left;
  }

  .mapping-top {
    font-size: 1.1em;
    text-align: justify;
    img {
      float: left;
      width: 200px;
      padding-right: 1.5em;
      padding-bottom: 1.5em;
      margin: 0;
      vertical-align: text-top;
    }
  }
  .mapping-centre {
    font-size: 1.1em;
    text-align: justify;
  }

  .mapping-bottom {
    background: darkgreen;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: row wrap;
  }

  .flex-item {
    padding: 10px;
    width: 33.3%;
    order: 1;
  }
   .flex-item img {
     max-width: 100%;
     object-fit: contain;
   }

  li {
    font-weight: bold;
  }

  .mapping-intro {
    font-weight: bold;
  }
}
</style>
