<template>
  <div class="about">
    <h1>What GrassCo can do for you</h1>
    <p>
      Pasture is a Kiwi farmer’s greatest asset, but an incredible amount of
      profitability, production and environmental sustainability will remain
      untapped on dairy farms while farmers fail to manage their pasture
      correctly. They use out of date technology to measure their pasture, or
      worse, none at all: reading pasture by eye, however experienced the
      farmer, leaves a lot of room for error.
    </p>
    <p>
      The pasture monitoring technology used by GrassCo is second-to-none. A
      custom-built pasture sledge with CDAX pasture console and reader is the
      cornerstone of the business, generating accurate and meaningful data to
      aid decision making up to a year in advance.
    </p>
    <p>
      Superb pasture management not only results in a significant increase in
      milksolids produced per cow, but helps farmers navigate unpredictable
      weather events that typically cause feed deficits throughout the season.
    </p>
    <p>
      New Zealand’s average milk production is 360-380kgMS/cow; however, GrassCo
      clients receiving weekly pasture monitoring are consistently reaching
      450-550kgMS/cow – a significant increase in milk production, and
      profitability. They are doing it with less cows, too, with some clients
      reducing herd sizes by 60-80 cows.
    </p>
    <p>
      Millions of dollars are spent on farmer wellbeing services in an
      ‘ambulance at the bottom of the cliff’ approach, after devastating
      droughts or other climatic events result in plummeting milk production,
      leaving farmers in dire financial positions. However, superb pasture
      management has the ability to help farmers avoid or greatly reduce the
      likelihood of this devastating scenario.
    </p>
    <p>
      GrassCo can now offer Canterbury farmers the same incredible pasture
      management service as its Southland clients, with the opening of a new
      branch in Mid-Canterbury.
    </p>
    <p>
      Farmers underestimate their ability to manage their pasture, but with the
      right information they can take their production, profitability and
      environmental sustainability to a level they didn’t know existed. What
      gets monitored gets managed.
    </p>
  </div>
</template>

<style scoped lang="scss">

.about {
    
    text-align: justify;
    font-size: 1.2em;  
    background:white; 
    padding: 1em; 
              
    h1 {
        font-size: 1.8em;
        text-align: left;
    }
    
}
</style>