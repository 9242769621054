<template>
  <info-page
    message="Temperature Monitoring, find out how it can help. Get in touch."
  >
    <div class="mapping-info">
      <h1>Temperature Monitoring</h1>
      <div>
        <div class="mapping-top">
          <img src="../photos/donald-temperature.jpg" alt="Graph" />
          <p>
            <span class="mapping-intro">
              Temperature Monitoring helps determine the factors effecting grass
              growth and can forecast development or identify problems.
            </span>
          </p>
          <p>
            Rye Grass production does not begin until 6degrees minimum and
            Clover production occurs at 13degrees so temperature monitoring ties
            in well with the pasture measurement results and allows you to see
            the bigger picture on how well your pastures are producing.
          </p>
        </div>
      </div>
      <div class="mapping-centre">
        <ul>
          <li>Free service providing weekly updates</li>
          <li>Used in conjunction pasture data for top production</li>
          <li>
            Soil temperature and grass growth info work parallel with each other
          </li>
          <li>Indicates where pasture growth is heading</li>
        </ul>
      </div>
      <div class="mapping-bottom">
        <div class="flex-item">
          <img
            src="../photos/temp-monitoring-1.jpg"
            alt="Temperature Monitoring 1"
          />
        </div>
        <div class="flex-item">
          <img
            src="../photos/temp-monitoring-2.jpg"
            alt="Temperature Monitoring 1"
          />
        </div>
        <div class="flex-item">
          <img
            src="../photos/temp-monitoring-3.jpg"
            alt="Temperature Monitoring 1"
          />
        </div>
        <div class="flex-item">
          <img
            src="../photos/temp-monitoring-4.jpg"
            alt="Temperature Monitoring 1"
          />
        </div>
      </div>
    </div>
  </info-page>
</template>

<script>
import InfoPage from "../components/InfoPage.vue";

export default {
  name: "Mapping",
  components: {
    InfoPage,
  },
};
</script>

<style lang="scss">
.mapping-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 2em;
    color: green;
    text-align: left;
  }

  .mapping-top {
    font-size: 1.1em;
    text-align: justify;
    img {
      float: left;
      width: 200px;
      padding-right: 1.5em;
      padding-bottom: 1.5em;
      margin: 0;
      vertical-align: text-top;
    }
  }
  .mapping-centre {
    font-size: 1.1em;
    text-align: justify;
  }

  .mapping-bottom {
    background: darkgreen;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: row wrap;
  }

  .flex-item {
    padding: 10px;
    width: 33.3%;
    order: 1;
  }
  .flex-item img {
    max-width: 100%;
    object-fit: contain;
  }

  li {
    font-weight: bold;
  }

  .mapping-intro {
    font-weight: bold;
  }
}
</style>
