<template>
  <section class="info-section">
    <Slider />
    <div class="container">
      <div class="main-content">
        <slot></slot>
      </div>
      <div class="pict-links">
        <PictLink
          title="Temperature"
          desc="We can accurately measure the grass cover and grass growth in your paddock."
          :src="require('../images/temperature.jpg')"
          url="Temperature"
        />
        <PictLink
          title="Farm Mapping"
          desc="Using detailed GPS tracking we can generate accurate information to help you manage your pasture."
          :src="require('../images/map.jpg')"
          url="Mapping"
        />
        <PictLink
          title="GrassCo Cover"
          desc="Know your Farm. Determine the factors effecting grass growth on your paddock."
          :src="require('../images/grass.jpg')"
          url="Pasture"
        />
      </div>
    </div>
    <div class="call-us">
      <div class="call-us-left">
        <div class="happy-to-help">
          {{ message }}
        </div>
        <div class="owners">
          - Donald & Andrea Martin, Owners
        </div>
      </div>
      <div class="call-us-right">
        <router-link to="/ContactUs">Contact Us</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import PictLink from "../components/PictLink.vue";
import Slider from "../components/Slider.vue";

export default {
  name: "Info",
  components: {
    PictLink,
    Slider,
  },
  props: ["message"],
};
</script>

<style lang="scss">
.info-section {
  .title-img {
    display: block;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .container {
    max-width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    justify-content: space-between;
  }

  .main-content {
    display: block;
  }
  .pict-links {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 1em;
    display: block;
    width: 95%;
  }

  .call-us {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .info-section {
    .container {
      display: flex;
    }

    .pict-links {
      margin: 0;
      padding: 1em 1em;
      display: block;
      min-width: 300px;
      max-width: 300px;
    }

    .call-us {
      margin-top: 1em;
      padding: 1em 1em;
      display: flex;
      flex-direction: row;
      background-color: #fafafa;
      justify-content: space-between;
      align-items: center;

      .call-us-left {
        justify-content: space-between;
        padding: 1em 3em 1em 0px;

        .happy-to-help {
          display: block;
          font-size: 2em;
          color: black;
        }
        .owners {
          display: block;
          font-size: 1.2em;
          color: red;
          padding: 10px;
        }
      }

      a {
        width: 200px;
        font-size: 2em;
        display: block;
        background: #007af3;
        margin: 0;
        padding: 1.5em;
        text-decoration: none;
        color: white;
        border-radius: 1em;
        &:hover {
          background: skyblue;
          color: black;
        }
      }
    }
  }
}
</style>
