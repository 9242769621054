<template>
  <div class="home">
    <!--img alt="Vue logo" src="../assets/logo.png"-->
    <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
    <Hero />
    <Assurance />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import Hero from "@/components/Hero.vue";
import Assurance from "@/components/Assurance.vue";


export default {
  name: "Home",
  components: {
    Hero,
    Assurance,
  },
 };
</script>
