<template>
  <section class="contact-section">
    <div class="container">
      <div class="contact-left">
        <h2>Get in touch</h2>
        <div class="contact-greeting">
          We are here to help, give us a call or send us a message and we will
          get back to you as soon as we can.
        </div>
        <div class="contact-info">
          <div class="contact-item">
            <img src="../images/phone.png" alt="Phone" />
            <p>03 206 4668</p>
          </div>
          <div class="contact-item">
            <img src="../images/mobile.png" alt="Phone" />
            <p>027 240 4939</p>
          </div>
          <div class="contact-item">
            <img src="../images/email.png" alt="Phone" />
            <p>info@grassco.co.nz</p>
          </div>
        </div>
      </div>
      <div class="contact-right">
        <iframe
          defer
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2761.887136276358!2d168.8720381158302!3d-46.1928045791164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa82cd1270a47a063%3A0x4500ba346899208!2s22%20Doctors%20Road%2C%20Mataura%209712!5e0!3m2!1sen!2snz!4v1625896864873!5m2!1sen!2snz"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.contact-section {
  .contact-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2 {
    font-size: 2em;
    color: green;
    text-align: left;
  }

  .contact-greeting {
    font-size: 1.5em;
    color: green;
    text-align: left;
    max-width: 100%;
  }

  .contact-info {
    margin-top: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .contact-item {
    padding: 10px;
    width: 100%;
    order: 1;

    font-size: 1.2em;
    display: block;
    background: #fafafa;
    margin: 10px;
    color: black;
    border-radius: 1em;
  }

  h2 {
    font-size: 2em;
  }

  iframe {
    margin-top: 20px;
    width: 100%;
    height: 300px;
  }
}

@media only screen and (min-width: 1080px) {
  .contact-section {
    position: relative;

    .container {
      display: flex;
    }
  }
  .contact-left {
    width: 50%;
  }
  .contact-right {
    width: 50%;
  }

  .contact-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;
    height: 100%;

    iframe {
      height: 100%;
    }
  }
}
</style>
