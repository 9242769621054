<template>
  <div class="pict-link">
    <div class="pict-link-header">
      <span class="pict-link-left">
        <img :src="src" alt="Grass Cover" width="30" />
      </span>
      <span class="pict-link-right"> {{ title }}</span>
    </div>
    <p>{{ desc }}</p>
    <router-link :to="url" class="pict-link-btn">Find out more</router-link>
  </div>
</template>

<script>
export default {
  name: "PictLink",
  props: ["title", "desc", "src", "url"],
};
</script>

<style scoped lang="scss">
.pict-link {
  color: white;
  background: #04609c;
  text-align: center;
  padding: 0.5em 0.2em 0.1em 0.2em;
  width: 100%;
  margin: 0 auto 2em auto;
  border-radius: 1em;

  .pict-link-header {
    display: block;
    margin: 0;
    padding-bottom: 0.5em;
    font-size: 1.1em;
  }

  .pict-link-left {
    width: 30px;
    padding-right: 20px;
  }

  .pict-link-right {
    width: auto;
  }

   a {
      display: block;
      background: #007af3;
      margin: 1em;
      padding: 0.5em;
      text-decoration: none;
      color: white;
      border-radius: 1em;
      &:hover {
        background: var(--primary-color);
        color: white;
      }
    }
 

}
</style>
